import './image-text.scss';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

class ImageText {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-imagetext',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$imageText = $element;
        this.$imageTextContainer = this.$imageText.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$$imageTextSlides = this.$imageText.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.imageTextSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$imageTextContainer && this.$$imageTextSlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    initSlider () {
        this.imageTextSlider = new Swiper(this.$imageTextContainer, {
            modules: [Navigation, Pagination],
            speed: 500,
            effect: 'fade',
            slidesPerView: 1,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    checkSize () {
        if (this.$$imageTextSlides.length > 1 && window.innerWidth < 768) {
            if (this.sliderActive === false) {
                this.initSlider();
                this.sliderActive = true;
            }
        } else if (window.innerWidth >= 768) {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    destroySlider () {
        if (this.imageTextSlider !== null) {
            this.imageTextSlider.destroy(true, true);
            this.imageTextSlider = null;
        }
    }
}

export { ImageText };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$imageText = $context.querySelectorAll('[data-imagetext="root"]');
        for (let i = 0; i < $$imageText.length; i++) {
            const $imageText = $$imageText[i];

            $imageText.API = new ImageText($imageText);
        }
    }
});
